<template>
  <div class="about" @wheel.prevent="handleScroll">
    <header class="block" :class="{ testTransform1: isActive }">
      <p class="block__text">
        <a href="#" class="block__link">Independent art director</a> based on
        earth for ambitious brands & peoples
      </p>
    </header>
    <hr class="seperator" :class="{ testTransform1: isActive }" />
    <Menu :class="{ testTransform1: isActive }" />
    <Projets
      :typeProject="typeProject"
      :class="{ testTransform: isActive }"
      class="projets"
    />
    <Footer />
  </div>
</template>
<script>
import Menu from "@/components/Menu.vue";
import Projets from "@/components/Projets.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Menu,
    Projets,
    Footer,
  },
  data() {
    return {
      firstSlide: true,
      isActive: false,
      typeProject: [
        {
          label: "All work",
          link: "",
        },
        {
          label: "Digital",
          link: "",
        },
        {
          label: "Editorial",
          link: "",
        },
        {
          label: "Photography",
          link: "",
        },
        {
          label: "Motion",
          link: "",
        },
      ],
    };
  },
  methods: {
    handleScroll(e) {
      if (e.deltaY > 0) {
        this.isActive = true;
      }
      if (e.deltaY < 0) {
        this.isActive = false;
      }
      console.log(`scroll up ${e.deltaY}`);
      console.log(`scroll down ${e.deltaY}`);
      console.log("Le test est bon");
    },
  },
};
</script>
<style scoped>
.about {
  height: 100vh;
  overflow: hidden;
  background: aqua;
}

.projets {
  transform: translatey(3000px);
}
.block {
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.block__text {
  font-size: 1.6rem;
  text-align: center;
  max-width: 250px;
}

.block__link {
  text-decoration: underline;
}

.seperator {
  font-size: 0;
  opacity: 0.2;
  margin: 0 2rem;
}

/* test */
.testTransform {
  transform: translateY(-40vh);
  transition: all 0.3s ease-in-out;
}

.testTransform1 {
  transform: translateY(-50vh);
  transition: all 0.3s ease-in-out;
}
@media screen and (min-width: 700px) {
  .block__Project {
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    align-items: flex-end;
  }

  .block__text {
    font-size: 3rem;
    max-width: 530px;
  }

  .seperator {
    margin: 0 5rem;
  }
}
</style>
